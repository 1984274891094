import React from 'react';
import styled from 'styled-components';
import { H2, H3, Subtitle5 } from '@/components/Typography';
import bannerBg from '../assets/img/bannerFenaTerminal.png';
import FenaLogo from '../assets/icons/bigLogo.svg';
import QuickBooksLogo from '../assets/icons/quickbooksBigLogo.svg';
import ButtonDefault from '@/components/ButtonDefault';
import BottomMessageType3 from '@/components/BottomMessageType3';
import { LearnMoreQuickbooksAndFenaContent } from '@/pages/learn-more-about-quickbooks-and-fena';
import CarouselCard from '@/components/CarouselCard';

const ViewPortsWrapper = styled.div``;

const FirstViewportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  margin: 0 -15px;
  padding: 100px 20px 96px;
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 900px) {
    padding: 2rem 0;
    margin: 0 -20px;

    width: calc(100% + 10px * 2); // + margins
    box-sizing: border-box;
  }
`;

const ResponsibleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Plus = styled.p`
  font-family: Montserrat;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
`;

const SecondViewportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f4f7f9;
  padding: 60px 135px 100px;
  @media (max-width: 900px) {
    padding: 2rem 1rem;
  }
`;

const StyledH2 = styled(H2)`
  text-align: center;
  line-height: 54px;
  font-weight: 700;
  margin-bottom: 6px;
`;

const StyledH3 = styled(H3)`
  text-align: center;
  margin-bottom: 10px;
`;

const StyledSubtitle5 = styled(Subtitle5)`
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 60px;
`;

const StyledSubtitle5V3 = styled(Subtitle5)`
  text-align: center;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 50px;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 30px;
  @media (max-width: 900px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

const StyledCarouselCard = styled(CarouselCard)`
  margin: 0;
`;

const ThirdViewportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 135px;
  background-color: #fff;
  @media (max-width: 900px) {
    padding: 2rem 1rem;
  }
`;

interface LearnMoreQuickbooksAndFenaPageProps {
  content: LearnMoreQuickbooksAndFenaContent;
}

const LearnMoreQuickbooksAndFenaContainer: React.FunctionComponent<
  LearnMoreQuickbooksAndFenaPageProps
> = ({ content: { viewport1, viewport2, viewport3 } }) => (
  <ViewPortsWrapper>
    <FirstViewportWrapper resource={bannerBg}>
      <ResponsibleRow>
        <FenaLogo />
        <Plus className="accent-text-black">+</Plus>
        <QuickBooksLogo />
      </ResponsibleRow>
      <ButtonDefault to={viewport1.button.url}>
        {viewport1.button.label}
      </ButtonDefault>
    </FirstViewportWrapper>
    <SecondViewportWrapper>
      <StyledH3>{viewport2.title}</StyledH3>
      <StyledSubtitle5>{viewport2.subtitle}</StyledSubtitle5>
      <CardWrapper>
        {viewport2.descriptionCards.map((item) => (
          <StyledCarouselCard
            imgMarginBottom="20px"
            key={item.label}
            title={item.label}
            text={item.text}
            cardImgSrc={item.image}
          />
        ))}
      </CardWrapper>
    </SecondViewportWrapper>
    <ThirdViewportWrapper>
      <StyledH2>
        <span className="accent-text">{viewport3.title1} </span>
        {viewport3.title2}
      </StyledH2>
      <StyledSubtitle5V3>{viewport3.subtitle}</StyledSubtitle5V3>
      <BottomMessageType3
        bannerBg={bannerBg}
        button1={viewport3.buttons.button1}
        button2={viewport3.buttons.button2}
      />
    </ThirdViewportWrapper>
  </ViewPortsWrapper>
);

export default LearnMoreQuickbooksAndFenaContainer;
