import React from 'react';
import styled from 'styled-components';
import ButtonDefault from './ButtonDefault';

const Wrapper = styled.div`
  display: flex;
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  border-radius: 20px;
  padding: 80px 160px;
  display: flex;
  gap: 170px;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    padding: 2rem 1rem;
    gap: 70px;
  }
  @media (max-width: 900px) {
    padding: 2rem 1rem;
    gap: 20px;
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const ButtonTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;

const Line = styled.div`
  width: 2px;
  height: 120px;
  background: #fff;
  @media (max-width: 900px) {
    width: 120px;
    height: 2px;
  }
`;

interface ComponentProps {
  bannerBg: string;
  button1: {
    title: string;
    label: string;
    url: string;
  };
  button2: {
    title: string;
    label: string;
    url: string;
  };
}
const BottomMessageType3: React.FunctionComponent<ComponentProps> = ({
  bannerBg,
  button1,
  button2,
}) => (
  <Wrapper resource={bannerBg}>
    <ButtonWrapper>
      <ButtonTitle>{button1.title}</ButtonTitle>
      <ButtonDefault to={button1.url}>{button1.label}</ButtonDefault>
    </ButtonWrapper>
    <Line />
    <ButtonWrapper>
      <ButtonTitle>{button2.title}</ButtonTitle>
      <ButtonDefault to={button2.url}>{button2.label}</ButtonDefault>
    </ButtonWrapper>
  </Wrapper>
);

export default BottomMessageType3;
