import React from 'react';
import Main from '../containers/Layout';
import learnMoreQuickbooksAndFenaPageContent from '../../content/pages/learn-more-about-quickbooks-and-fena.yml';
import LearnMoreQuickbooksAndFenaContainer from '@/containers/Learn-more-about-quickbooks-and-fena';

export interface LearnMoreQuickbooksAndFenaContent {
  viewport1: {
    button: { label: string; url: string };
  };
  viewport2: {
    title: string;
    subtitle: string;
    descriptionCards: { label: string; text: string; image: string }[];
  };
  viewport3: {
    title1: string;
    title2: string;
    subtitle: string;
    buttons: {
      button1: { title: string; label: string; url: string };
      button2: { title: string; label: string; url: string };
    };
  };
}

const LearnMoreAboutQuickbooksAndFena: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3 } =
    learnMoreQuickbooksAndFenaPageContent as unknown as LearnMoreQuickbooksAndFenaContent;
  return (
    <Main>
      <LearnMoreQuickbooksAndFenaContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
        }}
      />
    </Main>
  );
};

export default LearnMoreAboutQuickbooksAndFena;
